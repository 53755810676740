import React, { useContext, useEffect, useState } from 'react';
import { Section } from '../components/Core';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalContext from '../context/GlobalContext';
import Seo from '../components/Seo/Seo';
import RecontractOrderJourneySteps from '../sections/RecontractOrderJourneySteps';
import { RecontractStepOne } from '../sections/RecontractSteps/StepOne';
import { RecontractStepTwo } from '../sections/RecontractSteps/StepTwo';
import { RecontractStepThree } from '../sections/RecontractSteps/StepThree';
import { RecontractStepFour } from '../sections/RecontractSteps/StepFour';
import Logo from '../components/Logo';

import { navigate } from 'gatsby';
import API from '../api/api';

const RecontractPage = (props) => {
  const gContext = useContext(GlobalContext);
  const checkoutStep = gContext?.goGetRegistrationData()?.checkoutStep || 1;
  const [fetchedOffer, setFetchedOffer] = useState(null);
  const [offerAPIError, setOfferAPIError] = useState(false);

  useEffect(() => {
    if (props.location.search.indexOf('BLACKNOV23') > -1) {
      navigate('/expired');
    }

    const params = new URLSearchParams(props.location.search);
    const step = params.get('step');
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      checkoutStep: step ? parseInt(step) : 1,
    });
  }, [props.location.search]);

  useEffect(() => {
    gContext.setHeaderVisible(false);
    gContext.setFooterVisible(false);

    const params = new URLSearchParams(props.location.search);
    const prevOrderId = params.get('pID');

    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      previousOrderId: prevOrderId,
    });

    API.get(
      `OrderModule/v1.0/db/Order/${prevOrderId}?entities=["OrderItem", "Contact"]&withLinks=true`,
    )
      .then((resp) => {
        if (resp.data.data.stage.key === 'OrderStageCancelled') {
          gContext.setAPIErrorMsg({
            title: 'Recontract Error',
            message: 'This order has already been renewed.',
          });
          navigate('/');
          return;
        }

        const orderObject = resp.data.data;
        const contactObject =
          orderObject.Contact.dbRecords && orderObject.Contact.dbRecords.length
            ? orderObject.Contact.dbRecords[0]
            : {};
        const orderItemObject =
          orderObject.OrderItem.dbRecords && orderObject.OrderItem.dbRecords.length
            ? orderObject.OrderItem.dbRecords
            : {};

        let hasVoiceProp = false;

        orderItemObject.forEach((orderItem) => {
          const hasVoice = orderItem.links.find(
            (item) => item.entity === 'ServiceModule:CustomerPhonePorting',
          );
          if (hasVoice) {
            hasVoiceProp = true;
          }
        });

        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          hasVoice: hasVoiceProp,
        });

        const baseProduct = orderObject.OrderItem.dbRecords.find(
          (item) => item.properties.ProductType === 'BASE_PRODUCT',
        );

        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          leadId: orderObject?.links?.find((item) => item.entity === 'CrmModule:Lead')?.id,
          contactId: orderObject?.links?.find((item) => item.entity === 'CrmModule:Contact')?.id,
          addressId: orderObject?.links?.find((item) => item.entity === 'CrmModule:Address')?.id,
          searchPremiseResults: {
            properties: {
              SalesStatus: 'ORDER',
            },
            title: orderObject?.links?.find((item) => item.entity === 'CrmModule:Address')?.title,
          },
          personalData: {
            firstName: contactObject?.properties?.FirstName,
            lastName: contactObject?.properties?.LastName,
            phoneNumber: contactObject?.properties?.Phone,
            email: contactObject?.properties?.EmailAddress,
            companyName: contactObject?.properties?.CompanyName,
            companyPosition: contactObject?.properties?.CompanyPosition,
          },
        });
      })
      .catch((error) => {
        gContext.setAPIErrorMsg({
          title: 'Recontract Error',
          message: error.response ? error.response?.data.message : error,
        });
        navigate('/');
        return;
      });
  }, []);

  useEffect(() => {
    let previousOrderId = '';

    if (typeof window !== 'undefined' && window) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      previousOrderId = urlParams.get('pID');
    }

    const fetchData = async () => {
      gContext.setQuoteLoading(true);
      try {
        setFetchedOffer(undefined);
        const query = `OrderModule/v1.0/orders/recontracting/get-quote?contractType=${
          gContext.productContractFilter === 'contractLow' ? 'MONTHLY' : 'CONTRACT'
        }`;
        const response = await API.post(query, {
          orderId: previousOrderId,
        });
        const nextOffer = response.data.data.nextOffer;
        const productsToSelectArray = response.data.data.nextProducts;
        const containsYouPhone = productsToSelectArray.some((product) =>
          product.title.includes('YouPhone'),
        );
        setFetchedOffer(nextOffer);
        gContext.setQuoteLoading(false);
        const offerAddonsArray = [];
        const offerAddons = nextOffer.Product.dbRecords.filter(
          (product) => product.properties.Type === 'ADD_ON_PRODUCT',
        );
        offerAddons.forEach((item) => {
          offerAddonsArray.push(item.properties.DisplayName);
        });

        /* Pass selected contractType along the order flow. */
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          contractType: gContext.productContractFilter === 'contractLow' ? 'MONTHLY' : 'CONTRACT',
          offerAddons: offerAddonsArray,
          recontractResponse: response.data.data,
          offer: nextOffer,
          contractText: renderContractLenght(
            productsToSelectArray.filter((item) => item.properties.Type === 'BASE_PRODUCT')[0],
          ),
          containsYouPhone,
        });

        if (productsToSelectArray) {
          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            selectedProduct: productsToSelectArray.filter(
              (item) => item.properties.Type === 'BASE_PRODUCT',
            )[0],
            orderItems: productsToSelectArray,
          });
        }
      } catch (error) {
        console.log('%c Error fetching offer.', 'color:red', error);
        setOfferAPIError(true);
        gContext.setQuoteLoading(false);
      }
    };
    fetchData();
  }, [gContext.productContractFilter]);

  const stepRouting = () => {
    if (gContext.goGetRegistrationData()) {
      switch (checkoutStep) {
        case 1:
          return (
            <RecontractStepOne
              location={props?.location?.state?.location}
              fetchedOffer={fetchedOffer}
            />
          );
        case 2:
          return <RecontractStepTwo />;
        case 3:
          return <RecontractStepThree />;
        case 4:
          return <RecontractStepFour />;
        default:
          <span>Something went wrong, no checkout step specified.</span>;
      }
    } else {
      return <></>;
    }
  };

  const renderContractLenght = (baseProduct) => {
    let contractLenght = 'Contract';
    contractLenght = baseProduct.properties.ContractType.split('_')[1] + ' Month Fixed Price';
    return contractLenght;
  };

  return (
    <>
      <Seo page="checkout" featuredImageUrl={undefined} />
      <Container>
        <Logo white={false}
          style={{marginTop: '-40px'}}/>
      </Container>
      <Section>
        <Container className="checkoutOrder">
          {/* Order Journey Step Indicator */}
          <Row className="justify-content-center">
            <Col sm={12} md={8} className="text-center mb-1 mb-md-3 mt-1">
              {gContext.goGetRegistrationData() && (
                <RecontractOrderJourneySteps currentStep={checkoutStep} />
              )}
            </Col>
          </Row>

          {/* Step Router */}
          {stepRouting()}
        </Container>
      </Section>
    </>
  );
};

export default RecontractPage;
